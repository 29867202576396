import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './site.css';
import $ from 'jquery';
$(document).on('click', '[data-check-all]', function () {
    var $this = $(this);
    var parent = $this.parents('.form-group');
    parent.find(':checkbox').prop('checked', true);
});
$(document).on('click', '[data-check-none]', function () {
    var $this = $(this);
    var parent = $this.parents('.form-group');
    parent.find(':checkbox').prop('checked', false);
});
